<template lang="html">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body form-card">
          <div class="col-sm-12">
            <b class="required">Tiêu đề</b>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <el-input
                placeholder="Tiếng việt"
                v-model="form.vi_title"
                :class="errors.has('vi_title')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="vi_title"
                data-vv-as="Tiêu đề"
              ></el-input>
              <span class="text-danger" v-if="errors.has('vi_title')">{{ errors.first('vi_title') }}</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <el-input
                placeholder="English"
                v-model="form.en_title"
                :class="errors.has('en_title')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="en_title"
                data-vv-as="Tiêu đề"
              ></el-input>
              <span class="text-danger" v-if="errors.has('en_title')">{{ errors.first('en_title') }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <b>Nhóm race</b>
            </div>
            <div class="col-sm-12">
              <my-select
                class="full-width"
                :attribute="typeRaces"
                placeholder="Nhóm race"
                v-model="form.type_id"
              >
              </my-select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <b class="required">Nội dung Tiếng Việt</b>
            </div>
            <div class="col-sm-12">
              <el-input
                type="textarea"
                :rows="3"
                placeholder="Nội dung tiếng Việt"
                v-model="form.vi_content"
                :class="errors.has('vi_content')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="vi_content"
                data-vv-as="Nội dung"
              >
              </el-input>
              <span class="text-danger" v-if="errors.has('vi_content')">{{ errors.first('vi_content') }}</span>
            </div>
          </div>
          <div>
            <div class="col-sm-12">
              <b class="required">Nội dung Tiếng Anh</b>
            </div>
            <div class="col-sm-12">
              <el-input
                type="textarea"
                :rows="3"
                placeholder="Nội dung tiếng Anh"
                v-model="form.en_content"
                :class="errors.has('en_content')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="en_content"
                data-vv-as="Nội dung"
              >
              </el-input>
              <span class="text-danger" v-if="errors.has('en_content')">{{ errors.first('en_content') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="page-action">
        <el-button @click="cancel">Quay lại</el-button>
        <el-button type="primary" @click="save">Lưu lại</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { Input, Upload, Table, TableColumn, Button, Message } from 'element-ui';
  import MySelect from 'src/components/UIComponents/Select'
  import { mapState } from 'vuex';
  import Editor from '@tinymce/tinymce-vue'

  export default {
    components: {
      ElInput: Input,
      ElButton: Button,
      ElUpload: Upload,
      ElTable: Table,
      ElTableColumn: TableColumn,
      Editor,
      MySelect,
      Message
    },
    data () {
      return {
        form: {},
        tableData: [],
        variantDetail: [],
        imageUrl: '',
        editRow: false,
        groups: {
          options: []
        }
      }
    },

    computed: {
      ...mapState(['typeRaces'])
    },

    mounted() {
      this.$store.dispatch('setPageTitle', 'Tạo thông báo');
      this.$store.dispatch('fetchTypeRaces');
      this.$store.dispatch('setCurrentActions', [{
        label: 'Lưu lại',
        type: 'primary',
        icon: '',
        callback: this.save
      }])
    },

    methods: {
      cancel() {
        this.$router.push({name: 'AllNotifications'});
      },
      save() {
        let self = this;
        this.$validator.validateAll().then(function (result) {
          if (result) {
            self.$store.dispatch('storeNotification', self.form);
            Message({
              message: 'Tạo mới thành công',
              type: 'success'
            });
            self.$router.push({name: 'AllNotifications'});
          }
        })
      }
    },

    watch: {
      typeRaces(newVal, oldVal){
        this.typeRaces.forEach(function (typeRace) {
          typeRace.value = typeRace.id;
        })
        this.typeRaces.options = this.typeRaces;
      }
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
